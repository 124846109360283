import { Fragment } from 'react';
import style from './styles/guides-styles.component.module.css';
import IconGuia from '../assets/imgs/icon-guia.png';
import IconInfo from '../assets/imgs/icon-information.png';

interface IGuides {
	labelInformation?: string;
	labelGuide?: string;
}

export default function Guides({ labelInformation, labelGuide }: IGuides) {
	return (
		<Fragment>
			{!labelInformation && !labelGuide ? (
				<div className={style.container}></div>
			) : (
				<div className={style.content}>
					<div className={style.headerAbout}>
						{labelInformation && (
							<a
								className={style.headerAboutContent}
								href={labelInformation}
								target="_blank"
								rel="noreferrer"
							>
								<img src={IconInfo} alt="icono información" />
								Acerca de este trámite
							</a>
						)}
						{labelGuide && (
							<a
								className={style.headerAboutContent}
								href={labelGuide}
								target="_blank"
								rel="noreferrer"
							>
								<img src={IconGuia} alt="icono guía" />
								Guía de usuario
							</a>
						)}
					</div>
				</div>
			)}
		</Fragment>
	);
}
