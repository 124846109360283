/* eslint-disable react-hooks/exhaustive-deps */
// Resources
import styles from '../style.module.css';

// Components
import CustomButton from '../../../components/button.component';
import { useNavigate } from 'react-router-dom';
import CustomModal from '../../../components/modal.component';
import { Fragment } from 'react/jsx-runtime';

interface ISuccessfullyRecoveredPwd {
	correo: string;
}

function SuccessfullyRecoveredPwd({ correo }: ISuccessfullyRecoveredPwd) {
	const navigator = useNavigate();
	return (
		<CustomModal openModal={true} type="successful">
			<Fragment>
				<p
					className="Text-default Color-2-grey"
					style={{
						margin: 0,
						textAlign: 'center',
						wordWrap: 'break-word',
						width: '100%',
					}}
				>
					En las próximas horas recibirás un mensaje de confirmación al correo
					electrónico: <span className="Text-Negrita">{correo}</span> con las
					instrucciones para entrar a tu cuenta. <br />
					<br /> Podrás localizarlo en tu bandeja de entrada o en correo no
					deseado. El remitente será{' '}
					<span className="Text-Negrita">Portal Empresarial Infonavit</span> y
					el asunto{' '}
					<span className="Text-Negrita">
						{' '}
						Tu usuario y contraseña del Portal Empresarial.
					</span>
				</p>
				<div className={styles.containerButton}>
					<CustomButton
						onClick={() => navigator('/')}
						styles={{ width: 240 }}
						variant="solid"
						label="Finalizar"
					/>
				</div>
			</Fragment>
		</CustomModal>
	);
}
export default SuccessfullyRecoveredPwd;
