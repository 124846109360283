import { Close } from '@mui/icons-material';
import { Alert } from '@mui/material';
import { Fragment } from 'react';

interface AlertInputProps {
	valid: boolean;
	message?: string | React.ReactChild;
	name: string;
	align?: string | undefined;
}

export function AlertInput({ name, valid, message, align }: AlertInputProps) {
	return (
		<Fragment>
			{!valid && message && (
				<Alert
					sx={{
						color: '#DD052B',
						fontSize: '16px',
						alignItems: align ? align : 'center',
						fontWeight: 400,
						padding: '0px 12px',
						backgroundColor: '#FFE2E5',
					}}
					severity="error"
					icon={<Close style={{ color: '#DD052B', width: '18px' }} />}
				>
					<span data-testid={`error-${name}`}>{message}</span>
				</Alert>
			)}
		</Fragment>
	);
}
