// Dependencies
import { useEffect, useState } from 'react';

// Resources
import { useApiRequest } from '../../../hooks/useApiRequest';
import {
	IRecoverPwdParams,
	IRecoverPwdResponse,
	IRecoverPwdInputs,
	IModal,
} from '../interfaces/auth.interface';
import { RESPONSE_CODES } from '../../../config/response-codes';

// Components
import { compareEmails, emailValid } from '../../../utils/validators';

export function useRecoverPwd() {
	const [codeInput, setCodeInput] = useState('');
	const [code, setCode] = useState('');
	const [refreshCaptcha, setRefreshCaptcha] = useState(false);
	const [errorCorreo, setErrorCorreo] = useState({ valid: false, msg: '' });
	const [validForm, setValidForm] = useState(true);
	const [openModal, setOpenModal] = useState(true);
	const [openLoading, setOpenLoading] = useState<boolean>(false);

	const [data, setData] = useState<IRecoverPwdInputs>({
		correo: '',
		confirmaCorreo: '',
	});
	const [errorConfirmaCorreo, setErrorConfirmaCorreo] = useState({
		valid: false,
		msg: '',
	});
	const [errorCaptcha, setErrorCaptcha] = useState(false);
	const [alert, setAlert] = useState<IModal>({
		title: '',
		open: false,
		body: '',
		type: '',
	});

	const {
		execute,
		value: valueLogin,
		status,
	} = useApiRequest<IRecoverPwdParams, IRecoverPwdResponse>({
		path: 'autentica/recoveryPassword',
	});

	useEffect(() => {
		handleValidateLogin();
	}, [valueLogin?.code, status]); // eslint-disable-line react-hooks/exhaustive-deps

	const handleValidateLogin = () => {
		if (status === 'success' && valueLogin?.code === RESPONSE_CODES.SUCCESSFUL) {
			setOpenLoading(false);
		} else if (
			(status === 'success' || status === 'error') &&
			valueLogin?.code !== RESPONSE_CODES.SUCCESSFUL
		) {
			setOpenLoading(false);
			hasRecoverPwdError();
		}
	};

	const hasRecoverPwdError = () => {
		setRefreshCaptcha(!refreshCaptcha);
		setOpenModal(true);
		setAlert({
			open: true,
			body: valueLogin?.message,
			type: 'error',
		});
	};

	const handleValidteFormFields = (
		correo: string,
		confirmaCorreo: string,
		validCaptcha: boolean
	) => {
		if (
			emailValid(correo).valid &&
			compareEmails(correo, confirmaCorreo).valid &&
			validCaptcha
		) {
			setValidForm(true);
		} else {
			setValidForm(false);
		}
	};

	const handleSetData = (event: React.ChangeEvent<HTMLInputElement>): void => {
		const { name, value } = event.target;
		if (name === 'correo') {
			const validate = emailValid(value);
			handleValidteFormFields(
				value,
				data.confirmaCorreo,
				codeInput.length > 0 ? true : false
			);
			setErrorCorreo(validate);
			if (data.confirmaCorreo !== '') {
				const validate = compareEmails(value, data.confirmaCorreo);
				setErrorConfirmaCorreo(validate);
			}
		} else if (name === 'confirmaCorreo') {
			const validate = compareEmails(data.correo, value);
			handleValidteFormFields(
				data.correo,
				value,
				codeInput.length > 0 ? true : false
			);
			setErrorConfirmaCorreo(validate);
		}
		setData({ ...data, [name]: value });
	};

	const handleValidate = (): void => {
		setOpenLoading(true);
		if (!errorCorreo.valid || !data.correo) {
			setErrorCorreo(emailValid(data.correo));
		} else if (!errorConfirmaCorreo.valid || !data.confirmaCorreo) {
			setErrorConfirmaCorreo(compareEmails(data.correo, data.confirmaCorreo));
		} else if (!code || codeInput !== code) {
			setRefreshCaptcha(!refreshCaptcha);
			setOpenLoading(false);
			return setErrorCaptcha(true);
		} else {
			execute({ ...data });
		}
	};

	return {
		handleSetData,
		handleValidate,
		setCodeInput,
		setCode,
		handleValidteFormFields,
		setOpenModal,
		setErrorCaptcha,
		data,
		codeInput,
		refreshCaptcha,
		errorCorreo,
		errorConfirmaCorreo,
		alert,
		valueLogin,
		status,
		validForm,
		errorCaptcha,
		openModal,
		setOpenLoading,
		openLoading,
	};
}
