import React, { Fragment, useContext } from 'react';
import CustomButton from '../../../../../../components/button.component';
import { TextInput, Captcha } from '../../../../../../components';
import { useEba } from './hooks/useEba';
import CustomModal from '../../../../../../components/modal.component';
import CustomAlertCC from '../../../custom-alert/custom-alert-cc.component';

import styles from './styles/eba-form.styles.component.module.css';
import GeneralDataForm from '../general-data-form';
import { CreateAccountContext } from '../../../../context/CreateAccountContext';

interface IEBAFrom {
	cancelOption: () => void;
	setActualStep: React.SetStateAction<any>;
	showPwd1: boolean;
	emmissionMessage: string;
}

const EBAForm = (props: IEBAFrom) => {
	const { createAccountData } = useContext(CreateAccountContext);

	const {
		errorEBA,
		codeInput,
		refreshCaptcha,
		validCaptcha,
		disabledButton,
		showModal,
		pwdConfirmation,
		modalMessage,
		setPwdConfirmation,
		setShowModal,
		handleSetData,
		setCode,
		setCodeInput,
		cancelEbaValidation,
		handleValidate,
		setValidCaptcha,
		errorContrasenia,
		setErrorContrasenia,
		errorConfirmacionContrasenia,
		setErrorConfirmacionContrasenia,
	} = useEba(props);

	return (
		<Fragment>
			<CustomModal
				openModal={showModal}
				labelButton="Cerrar"
				closeButton={true}
				setOpenModal={setShowModal}
				type="error"
			>
				<Fragment>{modalMessage}</Fragment>
			</CustomModal>
			<div className={styles.mainContainer}>
				<CustomAlertCC message="Validación del registro con EBA" />
				<span className={styles.ebaParagraph}>
					Ingresa los siguientes datos y valida la información con tu{' '}
					<b>EBA (Emisión Bimestral Anticipada)</b> emitida por el IMSS,
					correspondiente al Infonavit (Concepto vivienda y ACV) del:{' '}
					<span className={styles.bimestre2012Txt}>
						{props.emmissionMessage}
					</span>
				</span>
				<TextInput
					id="eba"
					name="eba"
					placeholder="p.e.3432.12"
					labelStyles={{ fontSize: 16 }}
					contentStyles={{ marginBottom: 40 }}
					value={createAccountData.eba!}
					onChange={handleSetData}
					labelComponent={
						<p className={styles.labelContainerInput}>
							Ingresa tu EBA<span className="mandatory">*</span>
						</p>
					}
					error={!errorEBA.valid}
					helperText={!errorEBA.valid ? errorEBA.msg : ''}
					maxLength={50}
				/>
				<hr className={styles.hrEba} />
				<GeneralDataForm
					pwdConfirmation={pwdConfirmation}
					setPwdConfirmation={setPwdConfirmation}
					errorContrasenia={errorContrasenia}
					setErrorContrasenia={setErrorContrasenia}
					errorConfirmacionContrasenia={errorConfirmacionContrasenia}
					setErrorConfirmacionContrasenia={setErrorConfirmacionContrasenia}
				/>
				<span className={styles.datosObligatoriosTxt}>
					<span className="mandatory">*</span>Datos obligatorios
				</span>
				<div className={styles.containerCaptcha}>
					<Captcha
						value={codeInput}
						getNew={refreshCaptcha}
						onChange={(newValue: string, codeCaptcha: string) => {
							setCodeInput(newValue);
							setCode(codeCaptcha);
						}}
						error={validCaptcha}
						setError={setValidCaptcha}
					/>
				</div>
				<div className={styles.containerButtonsForm}>
					<CustomButton
						onClick={cancelEbaValidation}
						variant="outlined"
						label="Regresar"
					/>
					<CustomButton
						onClick={handleValidate}
						variant="solid"
						disabled={disabledButton}
						label="Crear cuenta"
					/>
				</div>
			</div>
		</Fragment>
	);
};

export default EBAForm;
