import React, { Fragment, useState } from 'react';
import { MenuItem, Select } from '@mui/material';
import CustomAlert from './alert.component';

interface ISelect {
	dataToShow: Array<{ nombre: string; value: string }>;
	placeholder: string;
	defaultValue?: string;
	onChange: (event: React.ChangeEvent<HTMLSelectElement>) => void;
	onClose?: () => void;
	onOpen?: () => void;
	name: string;
	id: string;
	value?: string;
	helperText?: string;
	reversed?: boolean;
	disabled?: boolean;
	styles?: React.CSSProperties;
	onBlur?: (event: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
}

const CustomSelect = (props: ISelect) => {
	const [isSelected, setIsSelected] = useState<boolean>(false);

	const {
		dataToShow,
		reversed,
		name,
		id,
		value,
		helperText,
		disabled,
		styles,
		onChange,
		onClose,
		onOpen,
		placeholder,
		onBlur,
	} = props;

	const selectStyles = {
		'& .MuiSvgIcon-root': { color: '#293990' },
		'& .MuiInputBase-input': isSelected
			? {
					fontFamily: 'Geomanist',
					fontSize: '18px',
					bgcolor: 'white',
					color: 'black',
			  }
			: {
					fontFamily: 'Geomanist',
					fontSize: '18px',
					// bgcolor: '#FBFBFB',
					// padding: '0px',
					color: '#7F7F7F',
			  },
		'&.MuiOutlinedInput-root': {
			'& fieldset': {
				borderColor: '#C6C6C6',
			},
			'&:hover fieldset': {
				borderColor: '#C6C6C6',
			},
			'&.Mui-focused fieldset': {
				border: '1px solid #C6C6C6',
			},
		},
	};

	const menuProps = {
		PaperProps: {
			sx: {
				maxHeight: '336px',
				border: '1px solid #C6C6C6',
				boxShadow: 'none',
				'& .MuiMenuItem-root': {
					bgcolor: 'white',
					padding: 2,
					fontFamily: 'Geomanist',
					fontSize: '18px',
				},
				'& .MuiMenuItem-root:hover': {
					bgcolor: '#293990',
					color: 'white',
				},
				'& .Mui-selected': {
					backgroundColor: '#fff !important',
					'&:hover': { color: 'black' },
				},
			},
		},
	};

	const handleChange = (event: any) => {
		setIsSelected(true);
		onChange(event);
	};

	return (
		<Fragment>
			<Select
				id={id}
				name={name}
				style={{ ...styles }}
				fullWidth
				displayEmpty
				value={value}
				onChange={handleChange}
				onClose={onClose}
				onOpen={onOpen}
				sx={selectStyles}
				MenuProps={menuProps}
				disabled={disabled}
				data-testid={id}
				role="select"
				renderValue={value !== '' ? undefined : () => placeholder}
				onBlur={onBlur}
			>
				{reversed === true
					? dataToShow
							.slice(0)
							.reverse()
							.map((data, index) => {
								if (index !== 0) {
									return (
										<MenuItem
											value={data.value}
											key={data.value}
											role="option"
											data-testid={`option-${data.nombre}`}
										>
											{data.nombre}
										</MenuItem>
									);
								}
								return null;
							})
					: dataToShow.map((data, index) => {
							if (index !== 0) {
								return (
									<MenuItem
										value={data.value}
										key={data.value}
										role="option"
										data-testid={`option-${data.nombre}`}
									>
										{data.nombre}
									</MenuItem>
								);
							}
							return null;
					  })}
			</Select>
			{helperText && (
				<CustomAlert
					show={true}
					severity="error"
					data-testid={`alert-input-${id}`}
					message={helperText}
					hasMargin={true}
				/>
			)}
		</Fragment>
	);
};

export default CustomSelect;
