import { useState, useEffect, useLayoutEffect, useRef, Fragment } from 'react';
import { useNavigate } from 'react-router-dom';
// import logoInfonavit from '../assets/logos/infonavit.png';
import logoInfonavit from '../assets/svg/logo_portal.svg';

import arrowDown from '../assets/imgs/arrow-down-s-line.png';
import arrowUp from '../assets/imgs/arrow-up-s-line.png';
import iconUser from '../assets/imgs/icon-user.png';
import iconUserBlue from '../assets/imgs/icon-user-blue.png';
import style from './styles/header-styles.component.module.css';
import IconCustom from './icon-custom.component';
import arrowForwardIcon from '../assets/svg/arrow-left-blue.svg';
import iconLogout from '../assets/svg/logout.svg';
//import { useDispatch } from 'react-redux';
import { getProfile } from '../utils/getProfile';
import CustomModal from './modal.component';
import CustomButton from './button.component';
//import { setShowQuestion } from '../store/modules/questions';
import { Typography } from '@mui/material';
import { useIdle } from './hooks/useIdle';
import { useRefreshToken } from './hooks/useRefreshToken';
import { useOnClickOutside } from './hooks/useOnClickOutside';
import { setIdSistema } from '../store/modules/idSistema';
import { useDispatch } from 'react-redux';
import { setRouteMod } from '../store/modules/routeModified';
import { URLS_ENV } from '../constants/urls.constants';
// interface solo si se opta por cambiar al menu mobile diseño prototipo
/*interface IProps {
	setShowMenu: (value: boolean) => void;
	showMenu: boolean;
}*/

export default function Header() {
	const entorno = process.env.REACT_APP_ENV;
	const cadena = process.env.REACT_APP_TAG;
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const ref = useRef(null);
	const [isShow, setIsShow] = useState<boolean>(false);
	const [dataQuestion, setDataQuestion] = useState<boolean>(false);

	const [openModal, setOpenModal] = useState<boolean>(false);
	const [timeExpires, setTimeExpires] = useState<number>(0);

	const [profile, setProfile] = useState({
		NRP: '',
		companyName: '',
		name: '',
		job: '',
	});

	const { executeRefreshToken, openError, setOpenError, modalMessage } =
		useRefreshToken({ setTimeExpires });

	const handleClick = () => setIsShow(!isShow);
	useLayoutEffect(() => {
		const profile = getProfile();
		const servicePerfil = profile?.contacto.servicios || [];
		const isArray = servicePerfil instanceof Array;
		const servicePerfilArr = isArray ? servicePerfil : [servicePerfil];
		const arrId = servicePerfilArr?.map(
			(servicio: { descSistema: string; idSistema: string }) => {
				return servicio?.idSistema;
			}
		);

		if (profile) {
			let descriptionPerfil = '';
			if (profile.contacto.perfil.descPerfil === 'RL') {
				descriptionPerfil = 'Representante Legal';
			} else if (profile.contacto.perfil.descPerfil === 'RH') {
				descriptionPerfil = 'Recursos Humanos';
			} else if (profile.contacto.perfil.descPerfil === 'CP') {
				descriptionPerfil = 'Contador';
			}
			setProfile({
				NRP: profile.contacto.nrp,
				companyName: profile.razonSocial,
				name: `${profile.contacto.nombre} ${profile.contacto.apPaterno} ${profile.contacto.apMaterno}`,
				job: descriptionPerfil,
			});
			dispatch(setRouteMod(profile.contacto.perfil.descPerfil));
			dispatch(setIdSistema({ idSistemaArr: arrId }));
			sessionStorage.setItem('servicios', JSON.stringify(arrId));
		}
	}, []); // eslint-disable-line react-hooks/exhaustive-deps

	const signOff = () => {
		navigate('/');
		sessionStorage.removeItem('userData');
		sessionStorage.removeItem('key');
		sessionStorage.removeItem('servicios');
	};

	const navigateProfile = () => {
		navigate('/perfil');
		setIsShow(false);
	};

	const navigateAdmin = () => {
		navigate('/administracion-usuarios');
		setIsShow(false);
	};
	const active = () => executeRefreshToken();

	const {
		getRemainingTime,
		isPrompted,
		activate,
		remaining,
		setRemaining,
		openModalSession,
		setOpenModalSession,
	} = useIdle({ active, signOff, timeExpires });

	const handleStillHere = () => {
		setOpenModalSession(false);
		activate();
	};

	useEffect(() => {
		const interval = setInterval(() => {
			if (isPrompted()) {
				setRemaining(Math.ceil(getRemainingTime() / 1000));
			}
		}, 1000);
		return () => {
			clearInterval(interval);
		};
	}, [getRemainingTime, isPrompted]); // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		if (entorno === 'qa') {
			const partes = cadena ? cadena.split('-') : '';
			if (partes.length >= 2) {
				const ultimoValor = partes[partes.length - 1];
				console.log(`v0.0.1_${ultimoValor}`); //eslint-disable-line
			}
		} else if (entorno === 'production') {
			console.log('front_v1.2.1_10.12.2024'); //eslint-disable-line
		}
	}, []); // eslint-disable-line react-hooks/exhaustive-deps

	const handleClickOutside = () => setIsShow(false);
	useOnClickOutside(ref, handleClickOutside);
	return (
		<div className={style.container}>
			<div>
				<img
					className={style.logo}
					src={logoInfonavit}
					alt="Infonavit"
					onClick={() => navigate('/inicio')}
				/>
			</div>
			<div className={style.contentButtons}>
				<div className={style.content} ref={ref}>
					<button
						className={`${style.button} ${isShow ? style.buttonActive : ''}`}
						onClick={handleClick}
					>
						<IconCustom svg={iconUser} />
						<p className={style.description}>{profile.name}</p>{' '}
						<p className={style.description}>|</p>
						<span className={style.description}>NRP:</span>{' '}
						<p className={style.description}>{profile.NRP}</p>
						<img
							src={isShow ? arrowUp : arrowDown}
							className={style.description}
							alt="icon user"
						/>
					</button>
					<div className={style.content}>
						<div className={`${style.modal} ${isShow ? style.active : ''}`}>
							<div className={style.userName}>
								<IconCustom svg={iconUserBlue} />
								<p>{profile.name.toLocaleLowerCase()}</p>
							</div>
							<div className={style.infoContent}>
								<div className={style.item}>
									<p>NRP</p>
									<p>{profile.NRP}</p>
								</div>
								<div className={style.item}>
									<p>Razón social</p>
									<p>{profile.companyName.toLocaleLowerCase()}</p>
								</div>
								<div className={style.item}>
									<p>Contacto</p>
									<p>{profile.name.toLocaleLowerCase()}</p>
								</div>
								<div className={style.item}>
									<p>Puesto</p>
									<p>{profile.job}</p>
								</div>
							</div>
							<div className={style.line}></div>
							<div className={style.links}>
								<button
									onClick={navigateProfile}
									className={style.link}
									key="mi-perfil"
								>
									Mi perfil <IconCustom svg={arrowForwardIcon} />
								</button>

								{profile.job === 'Representante Legal' && (
									<button
										onClick={navigateAdmin}
										className={style.link}
									>
										Administración de usuarios{' '}
										<IconCustom svg={arrowForwardIcon} />
									</button>
								)}
							</div>
							<div className={style.line}></div>
							<button
								className={style.logout}
								onClick={() => setOpenModal(true)}
							>
								<p>Cerrar sesión</p>
								<IconCustom svg={iconLogout} />
							</button>
						</div>
					</div>
				</div>
			</div>

			<CustomModal
				openModal={openModal}
				setOpenModal={setOpenModal}
				title=""
				type="error"
				labelButton="Cancelar"
				closeButton={false}
				style={{ fontSize: 18, fontWeight: 400 }}
			>
				<Fragment>
					¿Estás seguro de que quieres cerrar sesión?
					<div className={style.buttonsClose}>
						<div className={style.buttonClose}>
							<CustomButton
								onClick={() => {
									setOpenModal(false);
									setDataQuestion(true);
								}}
								variant="solid"
								label="Cerrar"
							/>
						</div>
						<div className={style.buttonClose}>
							<CustomButton
								onClick={() => setOpenModal(false)}
								variant="outlined"
								label="Cancelar"
							/>
						</div>
					</div>
				</Fragment>
			</CustomModal>
			<CustomModal
				openModal={openModalSession}
				setOpenModal={setOpenModalSession}
				closeIconCallback={() => {
					setOpenModalSession(false);
					setDataQuestion(true);
				}}
				title="Cierre de sesión"
				type="normal"
				style={{ fontSize: 18, fontWeight: 400 }}
			>
				<Fragment>
					<p>
						Tu sesión finalizará en
						<br />
						<Typography
							component={'span'}
							style={{
								color: ' rgba(221, 5, 43, 1)',
								fontSize: '30px',
								fontWeight: '500',
								margin: 5,
							}}
						>
							{remaining === 60 || remaining === 60000
								? '1:00'
								: `${
										remaining.toString().length === 2
											? `00:${remaining}`
											: `00:0${remaining}`
								  }`}
						</Typography>
						<br />
						Haz clic en el botón que corresponda
					</p>
					<div className={style.buttonsClose}>
						<div className={style.buttonClose}>
							<CustomButton
								onClick={() => {
									setOpenModalSession(false);
									setDataQuestion(true);
								}}
								variant="outlined"
								label="Terminar sesión"
							/>
						</div>
						<div className={style.buttonClose}>
							<CustomButton
								onClick={handleStillHere}
								variant="solid"
								label="Continuar sesión"
							/>
						</div>
					</div>
				</Fragment>
			</CustomModal>
			<CustomModal
				openModal={openError}
				setOpenModal={setOpenError}
				title="Cerrar"
				type="error"
				style={{ fontSize: 18, fontWeight: 400 }}
				callback={() => signOff()}
			>
				{modalMessage}
			</CustomModal>
			<CustomModal
				openModal={dataQuestion}
				setOpenModal={setDataQuestion}
				title="Cierre de sesión"
				type="normal"
				style={{ fontSize: 18, fontWeight: 400 }}
				closeIconCallback={signOff}
			>
				<Fragment>
					<p className={style.text}>
						Con el fin de mejorar nuestros servicios, te agradecemos que
						contestes las siguientes preguntas sobre el Portal Empresarial
					</p>
					<div className={style.buttons}>
						<div className={style.button2}>
							<CustomButton
								onClick={signOff}
								variant="outlined"
								label="Salir"
							/>
						</div>
						<div className={style.button1}>
							<a
								href={URLS_ENV.ENCUESTA}
								target="_blank"
								rel="noreferrer"
								style={{ textDecoration: 'none' }}
							>
								<CustomButton
									onClick={() => {
										setDataQuestion(false);
										signOff();
									}}
									variant="solid"
									label="Aceptar"
								/>
							</a>
						</div>
					</div>
				</Fragment>
			</CustomModal>
		</div>
	);
}
