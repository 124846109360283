/* eslint-disable react-hooks/exhaustive-deps */

import { useState, useMemo, useEffect } from 'react';
import { useEfirmaValidation } from '../../../../../hooks/validateEfirmaFunction';
import { useDispatch } from 'react-redux';
import { IFormComponent } from '..';
import { EFIRMA_MESSAGES, INTERNAL_MESSAGES } from '../../../../../config/response-codes';
import { URLS } from '../../../../../constants/urls.constants';
import { useApiRequest } from '../../../../../hooks/useApiRequest';
import {
	defaulEfirmaValidationResponse,
	IDatosEfirma,
	IResponseEfirmaValidation,
} from '../../../../../interfaces/efirma.interface';
import { NRP_ERROR_MESSAGES } from '../../../constants';
import { RemovalOfRepresentativeResponse } from '../interfaces';
import { setLoadingRequest } from '../../../../../store/modules/loadingRequest';

declare global {
	interface Window {
		validarEFirma: any;
	}
}

export function useForm({
	setActualPage,
	setPdf,
	nrp,
	setNrp,
	setFolio,
	eFirmaData,
	setEFirmaData,
	setCadenaOriginal,
}: IFormComponent) {
	const validarEFirma = window.validarEFirma;

	const dispatch = useDispatch();
	const pswDefaultValue = '';

	const [resetData, setResetData] = useState<boolean>(false);
	const [efirmaValidationResponseData, setEfirmaValidationResponseData] =
		useState<IResponseEfirmaValidation>(defaulEfirmaValidationResponse);

	const [errorPassEfirma, setErrorPassEfirma] = useState({
		valid: false,
		msg: '',
	});
	const [errorKeyFile, setErrorKeyFile] = useState({
		valid: false,
		msg: '',
	});
	const [errorCerFile, setErrorCerFile] = useState({
		valid: false,
		msg: '',
	});

	const [rfcEfirma, setRfcEfirma] = useState<string>(''); //ver

	const [nrpError, setNrpError] = useState({ valid: false, msg: '' });

	const [showModal, setShowModal] = useState(false);
	const [modalMessage, setModalMessage] = useState('');

	const [showConfirmationModal, setShowConfirmationModal] = useState(false);

	const [isLoading, setIsLoading] = useState<boolean>(false);

	const { validateEfirmaFunction } = useEfirmaValidation({
		setEfirmaValidationResponseData,
		setModalMessage,
		setShowModal,
		formHasCapcha: false,
		setErrorPassEfirma,
		setErrorKeyFile,
		setErrorCerFile,
	});

	const disabledButton = useMemo((): boolean => {
		if (
			eFirmaData.psw !== '' &&
			eFirmaData.keyBinary !== '' &&
			eFirmaData.cerB64 !== '' &&
			eFirmaData.cerBinary !== '' &&
			nrpError.valid
		) {
			return false;
		}
		return true;
	}, [
		eFirmaData.cerB64,
		eFirmaData.cerBinary,
		eFirmaData.keyBinary,
		eFirmaData.psw,
		nrpError,
	]); // eslint-disable-next-line react-hooks/exhaustive-deps

	const { execute, value, status } = useApiRequest<
		unknown,
		RemovalOfRepresentativeResponse
	>({
		path: 'baja-representante-legal/baja-representante',
	});

	useEffect(() => {
		if (status === 'success') {
			setShowConfirmationModal(false);
			setIsLoading(false);
			switch (value?.code) {
				case 'PDF00':
					setPdf(value.data?.base64!);
					setFolio(value.data?.folio!);
					setCadenaOriginal(value.data?.cadenaOriginal!);
					setActualPage('confirmation');
					break;
				case '0002':
				case '0003':
					setModalMessage(value.message);
					setShowModal(true);
					handleCancelConfirmation();
					break;
				case '0001':
				case '0006':
					setNrpError({ valid: false, msg: 'Usuario no registrado.' });
					setEFirmaData({
						keyBinary: '',
						cerBinary: '',
						cerB64: '',
						psw: '',
					});
					setEfirmaValidationResponseData(defaulEfirmaValidationResponse);
					setRfcEfirma('');
					break;
				case '0108':
					setModalMessage('Problemas con el Sellado Digital.');
					setShowModal(true);
					handleCancelConfirmation();
					break;
				default:
					setModalMessage(INTERNAL_MESSAGES.ERROR_MESSAGE);
					setShowModal(true);
					handleCancelConfirmation();
					break;
			}
		} else if (status === 'error') {
			if (value?.internalCode === 'FV000') {
				setModalMessage('Problemas con el Sellado Digital.');
			} else {
				setModalMessage(INTERNAL_MESSAGES.ERROR_MESSAGE);
			}
			setIsLoading(false);
			setShowConfirmationModal(false);
			setShowModal(true);
			handleCancelConfirmation();
		}
	}, [status]);

	useEffect(() => {
		if (efirmaValidationResponseData.valido) {
			dispatch(setLoadingRequest({ loadingRequest: false }));
			setRfcEfirma(efirmaValidationResponseData.rfc);
			setShowConfirmationModal(true);
		}
	}, [efirmaValidationResponseData]); // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		if (errorPassEfirma.msg === EFIRMA_MESSAGES.INCORRECT_PSW) {
			setEFirmaData((prev: IDatosEfirma) => ({
				...prev,
				password: pswDefaultValue,
			}));
		}
	}, [errorPassEfirma.msg]);

	const handleNrpChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
		const { value } = event.target;
		if (!value.match(/^[a-zA-Z0-9]*$/)) return;
		const reg = /^([a-zA-Z\-0-9]{3})([0-9]{8})$/;
		if (value === '') {
			setNrpError({ valid: false, msg: NRP_ERROR_MESSAGES.EMPTY_NRP });
		} else if (!value.match(reg)) {
			setNrpError({ valid: false, msg: NRP_ERROR_MESSAGES.INVALID_NRP });
		} else {
			setNrpError({ valid: true, msg: '' });
		}
		setNrp(value.toUpperCase());
	};

	const handleCancelConfirmation = (): void => {
		setEfirmaValidationResponseData(defaulEfirmaValidationResponse);
		setRfcEfirma('');
		setResetData(!resetData);
		setEFirmaData({
			keyBinary: '',
			cerBinary: '',
			cerB64: '',
			psw: '',
		});
		setPdf('');
		setFolio('');
		setNrp('');
		setShowConfirmationModal(false);
	};

	const handleAcceptConfirmation = () => {
		execute({ nrp: nrp, rfc: rfcEfirma, firmar: false });
		window.scrollTo(0, 0);
		setIsLoading(true);
	};

	const validateFiel = () => {
		dispatch(setLoadingRequest({ loadingRequest: true }));
		if (typeof validarEFirma === 'function') {
			validarEFirma(
				{
					...eFirmaData,
					password: eFirmaData.psw,
					serverIP: URLS.EFIRMA.VALIDATE,
				},
				validateEfirmaFunction
			);
		} else {
			setModalMessage(INTERNAL_MESSAGES.ERROR_MESSAGE);
			setShowModal(true);
		}
	};

	return {
		disabledButton,
		eFirmaData,
		errorPassEfirma,
		errorKeyFile,
		errorCerFile,
		nrp,
		nrpError,
		showModal,
		modalMessage,
		showConfirmationModal,
		resetData,
		isLoading,
		setEFirmaData,
		handleNrpChange,
		setShowModal,
		validateFiel,
		setShowConfirmationModal,
		handleCancelConfirmation,
		handleAcceptConfirmation,
	};
}
