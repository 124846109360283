import { Fragment } from 'react';
import CustomModal from '../modal.component';
import CustomButton from '../button.component';
import useModalErrorStore from '../../store/modules/modal-error/useModalErrorStore';

export function ModalError() {
	const { modalError, closeModalHandler } = useModalErrorStore();
	return (
		<CustomModal openModal={modalError.openModal} type={modalError.type}>
			<Fragment>
				<p>{modalError.message}</p>
				<CustomButton
					label="Cerrar"
					variant="solid"
					styles={{ maxWidth: '211px' }}
					onClick={closeModalHandler}
				/>
			</Fragment>
		</CustomModal>
	);
}
