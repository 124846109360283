import { useEffect, useState } from 'react';
import acceptedIcon from '../assets/svg/checkbox-circle-fill.svg';
import unacceptedIcon from '../assets/svg/minus-circle-fill.svg';
import styles from './styles/pwd-styles.component.module.css';

interface IPsw {
	psw: string;
}

const PwdComponent = ({ psw }: IPsw) => {
	const [pwdStructure, setPwdStructure] = useState({
		upperCase: false,
		lowerCase: false,
		number: false,
		specialCharacter: false,
		length10: false,
	});

	useEffect(() => {
		verifyPwdStructure(psw);
	}, [psw]);

	const verifyPwdStructure = (psw: string) => {
		const upperCaseLetters = /[A-Z]/g;
		const specialCharacter = /[*@!#~{}";:¨?¿¡$,._'-]/g;
		const numbers = /[0-9]/g;
		const lowerCaseLetters = /[a-z]/g;

		if (psw.match(upperCaseLetters)) {
			setPwdStructure((pwdStructure) => ({
				...pwdStructure,
				upperCase: true,
			}));
		} else {
			setPwdStructure((pwdStructure) => ({
				...pwdStructure,
				upperCase: false,
			}));
		}
		if (psw.match(lowerCaseLetters)) {
			setPwdStructure((pwdStructure) => ({
				...pwdStructure,
				lowerCase: true,
			}));
		} else {
			setPwdStructure((pwdStructure) => ({
				...pwdStructure,
				lowerCase: false,
			}));
		}

		if (psw.match(numbers)) {
			setPwdStructure((pwdStructure) => ({
				...pwdStructure,
				number: true,
			}));
		} else {
			setPwdStructure((pwdStructure) => ({
				...pwdStructure,
				number: false,
			}));
		}

		if (psw.match(specialCharacter)) {
			setPwdStructure((pwdStructure) => ({
				...pwdStructure,
				specialCharacter: true,
			}));
		} else {
			setPwdStructure((pwdStructure) => ({
				...pwdStructure,
				specialCharacter: false,
			}));
		}

		if (psw.length >= 10) {
			setPwdStructure((pwdStructure) => ({
				...pwdStructure,
				length10: true,
			}));
		} else {
			setPwdStructure((pwdStructure) => ({
				...pwdStructure,
				length10: false,
			}));
		}
	};

	return (
		<div className={styles.content}>
			<span className={styles.elementosContraseñaTitulo}>
				Recuerda usar estos elementos en tu contraseña
			</span>
			<div className={styles.elementosContraseña}>
				<span className={styles.elementosContraseñaItem}>
					{pwdStructure.upperCase ? (
						<img src={acceptedIcon} alt="icono-aceptado" />
					) : (
						<img src={unacceptedIcon} alt="icono-no-aceptado" />
					)}
					Una mayúscula
				</span>
				<span className={styles.elementosContraseñaItem}>
					{pwdStructure.lowerCase ? (
						<img src={acceptedIcon} alt="icono-aceptado" />
					) : (
						<img src={unacceptedIcon} alt="icono-no-aceptado" />
					)}
					Una minúscula
				</span>
				<span className={styles.elementosContraseñaItem}>
					{pwdStructure.number ? (
						<img src={acceptedIcon} alt="icono-aceptado" />
					) : (
						<img src={unacceptedIcon} alt="icono-no-aceptado" />
					)}
					Un número
				</span>
				<span className={styles.elementosContraseñaItem}>
					{pwdStructure.specialCharacter ? (
						<img src={acceptedIcon} alt="icono-aceptado" />
					) : (
						<img src={unacceptedIcon} alt="icono-no-aceptado" />
					)}
					Un carácter especial (#,$,@)
				</span>
				<span className={styles.elementosContraseñaItem}>
					{pwdStructure.length10 ? (
						<img src={acceptedIcon} alt="icono-aceptado" />
					) : (
						<img src={unacceptedIcon} alt="icono-no-aceptado" />
					)}
					Diez caracteres mínimo
				</span>
			</div>
		</div>
	);
};

export default PwdComponent;
