/* eslint-disable react-hooks/exhaustive-deps */
import { Fragment } from 'react';
import Footer from '../../components/footer.component';
import Information from '../../components/information.component';
import FormRecoverPwd from './components/form-recover-pwd';

const RecoverPwd = () => {
	return (
		<Fragment>
			<FormRecoverPwd />
			<Information />
			<Footer />
		</Fragment>
	);
};
export default RecoverPwd;
