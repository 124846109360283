// Components
import { Link, List } from '@mui/material';

// Resources
import { SOCIALMEDIA } from './utils/footer.component.utils';
import styles from './styles/footer-styles.component.module.css';
import { URLS_ENV } from '../constants/urls.constants';

const Footer = () => {
	return (
		<div className={styles.content}>
			<div className={styles.container}>
				<div className={styles.subContainer70}>
					<div className={styles.containerLabelTitle}>
						<label className={styles.labelTitle}>Síguenos</label>
					</div>
					<List className={styles.socialMediaList}>
						{SOCIALMEDIA.map((sm) => (
							<Link
								key={sm.id}
								target="_blank"
								rel="noopener"
								href={sm.url}
							>
								<img
									src={sm.icon}
									width={
										sm.title === 'Facebook'
											? '10'
											: sm.title === 'Linkedin'
											? '18'
											: '20'
									}
									alt="icon"
								/>
							</Link>
						))}
					</List>
				</div>
				<div className={styles.subContainer30}>
					<div className={styles.containerLabelTitle}>
						<label className={styles.labelTitle}>Infonatel</label>
					</div>
					<div className={styles.containerLabelPhone}>
						<div className={styles.subContainerLabelPhone}>
							<label className={styles.labelPhone}>55 9171 5050</label>
							<label className={styles.labelCity}>Ciudad de México</label>
						</div>
						<div className={styles.subContainerLabelPhone}>
							<label className={styles.labelPhone}>800 008 3900</label>
							<label className={styles.labelCity}>
								Desde cualquier parte del país
							</label>
						</div>
					</div>
				</div>
			</div>
			<div className={styles.containerSubFooter}>
				<label className={styles.labelCopyright}>
					Derechos Reservados © Infonavit 2021
				</label>
				<a
					href={URLS_ENV.FOOTER_A}
					className={styles.links}
					target="_blank"
					rel="noopener noreferrer"
				>
					Protección de datos personales
				</a>
				<a
					href={URLS_ENV.FOOTER_B}
					className={styles.links}
					target="_blank"
					rel="noopener noreferrer"
				>
					Términos y condiciones
				</a>
			</div>
		</div>
	);
};

export default Footer;
