import { LazyExoticComponent } from 'react';

//routes
import LoginPage from '../pages/login';
import RecoverPwd from '../pages/recover-pwd';
import ActivateAccount from '../pages/activate-account';
import CrearCuenta from '../pages/create-account';
import RemovalOfRepresentative from '../pages/removal-of-representative';
import ActivateRegisterPage from '../pages/activate-register/activate-register';
import AccountActivation from '../pages/account-activation';
import LoginCRM from '../pages/external-login';
//import { ILoginCRMParams } from '../pages/external-login/interfaces/authCRM.interface';
//Lazy routes

type JSXComponent = () => JSX.Element;

interface Route {
	path: string;
	to: string;
	isPrivate?: boolean;
	name: string;
	Component: LazyExoticComponent<JSXComponent> | JSXComponent;
}

export const routesPublic: Route[] = [
	{
		to: '/',
		path: '/',
		name: '/',
		Component: LoginPage,
		isPrivate: false,
	},
	{
		to: '/activa-cuenta',
		path: '/activa-cuenta',
		name: 'Activar cuenta',
		Component: ActivateAccount,
		isPrivate: false,
	},
	{
		to: '/activa-registro',
		path: '/activa-registro',
		name: 'Activar registro',
		Component: ActivateRegisterPage,
		isPrivate: false,
	},
	{
		to: '/crear-cuenta',
		path: '/crear-cuenta',
		name: 'Crear cuenta',
		Component: CrearCuenta,
		isPrivate: false,
	},

	{
		to: '/baja-representante-legal',
		path: '/baja-representante-legal',
		name: 'Baja de representante legal',
		Component: RemovalOfRepresentative,
		isPrivate: false,
	},
	{
		to: '/olvide-contrasenia',
		path: '/olvide-contrasenia',
		name: 'Recuperar contraseña',
		Component: RecoverPwd,
		isPrivate: false,
	},
	{
		to: '/activacion-cuenta',
		path: '/activacion-cuenta',
		name: 'Activación de cuenta',
		Component: AccountActivation,
		isPrivate: false,
	},
	{
		to: '/external-login',
		path: '/external-login',
		name: 'External Login',
		Component: LoginCRM,
		isPrivate: false,
	},
];
