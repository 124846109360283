import React, { Fragment, useContext, useEffect, useState } from 'react';
import CustomButton from '../../../../components/button.component';
import EBAForm from './components/eba-form';
import FielForm from './components/fiel-form';
import styles from './styles/step-2.styles.component.module.css';
import CustomAlertCC from '../custom-alert/custom-alert-cc.component';
import { CreateAccountContext } from '../../context/CreateAccountContext';

interface IStep2 {
	setActualStep: React.SetStateAction<any>;
	showPwd1: boolean;
	nrpHasEmission: boolean;
	nrpEmissionMessage: string;
	setNrpHasEmission: any;

	setShowPwd1: any;
}

const Step2 = ({
	setActualStep,
	showPwd1,
	nrpHasEmission,
	nrpEmissionMessage,
	setShowPwd1,
}: IStep2) => {
	const [isSelectedOption, setIsSelectedOption] = useState(false);
	const [optionSelected, setOptionSelected] = useState('');
	const [showValidationForm, setShowValidationForm] = useState('');
	const { setShowPwd } = useContext(CreateAccountContext);
	const handleNextStep = (): void => {
		setShowValidationForm(optionSelected);
	};

	const handleBackStep = (): void => {
		setShowValidationForm('');
		setOptionSelected('');
		setShowPwd1(true);
		setShowPwd(true);
		setActualStep(0);
	};

	const handleOptionChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
		const { value } = event.target;
		setIsSelectedOption(true);
		setOptionSelected(value);
	};

	const cancelOption = (): void => {
		setIsSelectedOption(false);
		setShowValidationForm('');
		setOptionSelected('');
	};

	useEffect(() => {
		if (!nrpHasEmission) {
			setOptionSelected('fiel');
			setShowValidationForm('fiel');
		}
	}, []); // eslint-disable-line react-hooks/exhaustive-deps

	return (
		<Fragment>
			{showValidationForm === '' ? (
				<Fragment>
					<CustomAlertCC message="Validación de tu registro patronal" />
					<p className={`Text-Small ${styles.colorGray2}`}>
						Puedes utilizar el valor total de la emisión bimestral anticipada
						emitida por el IMSS (EBA) o los archivos de tu Firma Electrónica
						(e.firma) de la empresa.
					</p>
					<div className={styles.radioButtonsContainer}>
						<div className={styles.optionContainer}>
							<label className={styles.radioLabel}>
								<input
									type="radio"
									name="opcionValidacion"
									value="fiel"
									id="fiel"
									onChange={handleOptionChange}
								/>
								Valida con la e.firma [Empresa]
								<span></span>
							</label>
						</div>
						<div className={styles.optionContainer}>
							<label className={styles.radioLabel}>
								Valida con EBA
								<input
									type="radio"
									name="opcionValidacion"
									value="eba"
									id="eba"
									onChange={handleOptionChange}
								/>
								<span></span>
							</label>
						</div>
					</div>
					<div className={styles.containerButtonsForm}>
						<CustomButton
							onClick={handleBackStep}
							variant="outlined"
							label="Regresar"
						/>
						<CustomButton
							onClick={handleNextStep}
							variant="solid"
							label="Continuar"
							disabled={!isSelectedOption}
						/>
					</div>
				</Fragment>
			) : (
				''
			)}
			{showValidationForm === 'eba' ? (
				<EBAForm
					setActualStep={setActualStep}
					cancelOption={cancelOption}
					showPwd1={showPwd1}
					emmissionMessage={nrpEmissionMessage}
				/>
			) : (
				''
			)}
			{showValidationForm === 'fiel' ? (
				<FielForm
					setActualStep={setActualStep}
					cancelOption={cancelOption}
					showPwd1={showPwd1}
					nrpHasEmission={nrpHasEmission}
				/>
			) : (
				''
			)}
		</Fragment>
	);
};

export default Step2;
