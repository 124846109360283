export const nssValid = (value: string): { valid: boolean; msg: string } => {
	const reg = /^(\d{2})(\d{2})(\d{2})\d{5}$/;
	if (value === '')
		return {
			valid: false,
			msg: 'Escribe 11 caracteres numéricos. Intenta de nuevo.',
		};
	if (value.length < 11) {
		return {
			valid: false,
			msg: 'Escribe 11 caracteres numéricos. Intenta de nuevo.',
		};
	}
	if (!value.match(reg)) {
		return { valid: false, msg: 'NSS Incorrecto' };
	}
	return { valid: true, msg: 'NSS Correcto' };
};

export const nrpValid = (value: string): { valid: boolean; msg: string } => {
	const reg = /^([a-zA-Z\-0-9]{3})([0-9]{8})$/;
	if (value === '')
		return {
			valid: false,
			msg: 'Escribe el Número de Registro Patronal (NRP) de la empresa.',
		};
	if (value.length < 11) {
		return {
			valid: false,
			msg: 'El Número de Registro Patronal debe tener 11 caracteres, el primero puede ser una letra.',
		};
	}
	if (!value.match(reg)) {
		return {
			valid: false,
			msg: 'El Número de Registro Patronal debe tener 11 caracteres, el primero puede ser una letra.',
		};
	}
	return { valid: true, msg: 'NRP Correcto' };
};

export const emailValid = (value: string): { valid: boolean; msg: string } => {
	const reg = /^[a-zA-Z0-9\-_.]+@[a-zA-Z0-9\-_]+(?:\.[a-zA-Z0-9-_]+)*$/;
	if (value === '') return { valid: false, msg: 'Escribe tu correo electrónico.' };
	if (!value.match(reg)) {
		return {
			valid: false,
			msg: 'Escribe tu correo en el formato usuario@servidor.dominio. Ejemplo: "fernandez@hotmail.com."',
		};
	} else {
		return { valid: true, msg: 'Correo electrónico correcto' };
	}
};

export const isNumber = (value: string): boolean => {
	const reg = /^[0-9]+$/;
	if (value === '') return true;
	if (!value.match(reg)) {
		return false;
	}
	return true;
};

export const isEmpty = (prop: any) => {
	return (
		prop === false ||
		prop === null ||
		prop === undefined ||
		(Object.prototype.hasOwnProperty.call(prop, 'length') && prop.length === 0) ||
		(prop.constructor === Object && Object.keys(prop).length === 0)
	);
};
export const isPwdLogin = (pwd: string) => {
	if (pwd === '') {
		return { valid: false, msg: 'Escribe tu contraseña.' };
	} else {
		return {
			valid: true,
			msg: ' ',
		};
	}
};

export const validateCharacters = (psw: string) => {
	if (
		psw.includes('%') ||
		psw.includes('^') ||
		psw.includes('&') ||
		psw.includes('=') ||
		psw.includes('/') ||
		psw.includes('\\') ||
		psw.includes('°') ||
		psw.includes('(') ||
		psw.includes(')') ||
		psw.includes('+') ||
		psw.includes(']') ||
		psw.includes('[') ||
		psw.includes('`')
	) {
		let caracterror = 0;
		for (let i = 0; i < psw.length; i++) {
			if (
				psw[i] === '%' ||
				psw[i] === '^' ||
				psw[i] === '&' ||
				psw[i] === '=' ||
				psw[i] === '/' ||
				psw[i] === '\\' ||
				psw[i] === '°' ||
				psw[i] === '(' ||
				psw[i] === ')' ||
				psw[i] === '+' ||
				psw[i] === '[' ||
				psw[i] === ']' ||
				psw[i] === '`'
			) {
				caracterror = i;
			}
		}
		return psw[caracterror];
	} else {
		return null;
	}
};

export const isPwdOldValidLogin = (pwd: string) => {
	if (pwd === '') {
		return { valid: false, msg: 'Escribe la contraseña.' };
	} else {
		return { valid: true, msg: '' };
	}
};
export const isPwdOldValid = (pwd: string) => {
	const validate = validateCharacters(pwd);
	if (pwd === '') {
		return { valid: false, msg: 'Escribe la contraseña.' };
	} else if (validate) {
		return {
			valid: false,
			msg: `El signo de ${validate} no está permitido, puedes intentar con los siguientes signos   (-,$,_,#,.)`,
		};
	} else if (validNumberRepeat(pwd)) {
		return {
			valid: false,
			msg: 'Tu contraseña no debe tener más de tres números consecutivos, ejem. 1234, 5678.',
		};
	} else {
		return { valid: true, msg: '' };
	}
};

export const isPwdValid = (pwd: string) => {
	const validate = validateCharacters(pwd);
	if (validate) {
		return {
			valid: false,
			msg: `El signo de ${validate} no está permitido, puedes intentar con los siguientes signos   (-,$,_,#,.)`,
		};
	}
	if (pwd === '') {
		return { valid: false, msg: 'Escribe la contraseña.' };
	} else if (validNumberRepeat(pwd)) {
		return {
			valid: false,
			msg: 'Tu contraseña no debe tener más de tres números consecutivos, ejem. 1234, 5678.',
		};
	} else if (
		!pwd.match(/[A-Z]/g) ||
		!pwd.match(/[*@!#~{}"?;:¨¿¡$,._'-]+/) ||
		!pwd.match(/[0-9]/g) ||
		!pwd.match(/[a-z]/g) ||
		pwd.length < 10
	) {
		return {
			valid: false,
			msg: 'La contraseña no cumple con todos los requerimientos.',
		};
	} else {
		return { valid: true, msg: '' };
	}
};

export const isConfirmPwdValid = (pwd: string, confirmPwd: string) => {
	const validate = validateCharacters(pwd);
	if (pwd === '') {
		return { valid: false, msg: 'Escribe la contraseña.' };
	} else if (validate) {
		return {
			valid: false,
			msg: `El signo de ${validate} no está permitido, puedes intentar con los siguientes signos   (-,$,_,#,.)`,
		};
	} else if (pwd !== confirmPwd) {
		return { valid: false, msg: 'Las contraseñas introducidas no son iguales.' };
	} else if (validNumberRepeat(pwd)) {
		return {
			valid: false,
			msg: 'Tu contraseña no debe tener más de tres números consecutivos, ejem. 1234, 5678.',
		};
	} else {
		return { valid: true, msg: '' };
	}
};

export const compareEmails = (
	correo: string,
	confirmaCorreo: string
): { valid: boolean; msg: string } => {
	if (confirmaCorreo === '')
		return { valid: false, msg: 'Confirma tu correo electrónico.' };
	if (correo !== confirmaCorreo) {
		return { valid: false, msg: 'Los correos introducidos no son iguales.' };
	}
	return { valid: true, msg: 'Los correos coinciden' };
};

export const isNameValid = (
	nombre: string,
	campo: string
): { valid: boolean; msg: string } => {
	if (nombre === '') {
		switch (campo) {
			case 'nombre':
				return {
					valid: false,
					msg: 'Escribe tus nombres.',
				};
			case 'primerApellido':
				return {
					valid: false,
					msg: 'Escribe tu Primer apellido.',
				};
			case 'segundoApellido':
				return {
					valid: false,
					msg: 'Escribe tu Segundo apellido.',
				};
		}
	}
	if (!nombre.match(/^[a-zA-ZÀ-ÿ ()-]+(?: [a-zA-ZÀ-ÿ ()-]+)*$/gm)) {
		switch (campo) {
			case 'nombre':
				return {
					valid: false,
					msg: 'Tus nombres no pueden tener caracteres especiales, únicamente letras y espacio en caso de tener más de un nombre. Intenta de nuevo.',
				};
			case 'primerApellido':
				return {
					valid: false,
					msg: 'Tu Primer apellido no puede tener caracteres especiales, únicamente letras y espacio en caso de tener más de un nombre. Intenta de nuevo.',
				};
			case 'segundoApellido':
				return {
					valid: false,
					msg: 'Tu segundo apellido no puede tener caracteres especiales, únicamente letras y espacio en caso de tener más de un nombre. Intenta de nuevo.',
				};
		}
	}
	return { valid: true, msg: 'Nombres correctos' };
};

export const nameHasSpecialCharacters = (name: string): boolean => {
	if (!name.match(/^[a-zA-ZÀ-ÿ ]*$/gm)) {
		return true;
	}
	return false;
};

export const isRFCValid = (rfc: string) => {
	if (rfc === '') {
		return {
			valid: false,
			msg: 'Escribe tu Registro Federal de Contribuyentes (RFC).',
		};
	} else if (
		!rfc.match(
			/^([A-ZÑ&]{4}) ?(?:- ?)?(\d{2}(?:0[1-9]|1[0-2])(?:0[1-9]|[12]\d|3[01])) ?(?:- ?)?([A-Z\d]{2})([A\d])$/
		)
	) {
		return {
			valid: false,
			msg: 'El Registro Federal de Contribuyentes (RFC) debe tener 13 caracteres alfanuméricos.',
		};
	}
	return { valid: true, msg: '' };
};

export const isRFC = (rfc: string): boolean => {
	if (
		!rfc.match(
			/^([A-ZÑ&]{3,4}([0-9]{2})(0[1-9]|1[0-2])(0[1-9]|1[0-9]|2[0-9]|3[0-1]))([A-Z_0-9]{3})?$/
		)
	)
		return false;
	return true;
};

export const isCURPValid = (curp: string) => {
	if (curp === '') {
		return {
			valid: false,
			msg: 'Escribe tu Clave Única de Registro de Población (CURP). Intenta de nuevo.',
		};
	} else if (
		!curp.match(
			/^([A-Z][AEIOUX][A-Z]{2}\d{2}(?:0[1-9]|1[0-2])(?:0[1-9]|[12]\d|3[01])[HM](?:AS|B[CS]|C[CLMSH]|D[FG]|G[TR]|HG|JC|M[CNS]|N[ETL]|OC|PL|Q[TR]|S[PLR]|T[CSL]|VZ|YN|ZS)[B-DF-HJ-NP-TV-Z]{3}[A-Z\d])(\d)$/
		)
	) {
		return {
			valid: false,
			msg: 'La Clave Única de Registro de Población (CURP) debe tener 18 caracteres alfanuméricos.',
		};
	}
	return { valid: true, msg: '' };
};

export const isCurp = (curp: string): boolean => {
	if (
		!curp.match(
			/^([A-Z][AEIOUX][A-Z]{2}\d{2}(?:0[1-9]|1[0-2])(?:0[1-9]|[12]\d|3[01])[HM](?:AS|B[CS]|C[CLMSH]|D[FG]|G[TR]|HG|JC|M[CNS]|N[ETL]|OC|PL|Q[TR]|S[PLR]|T[CSL]|VZ|YN|ZS)[B-DF-HJ-NP-TV-Z]{3}[A-Z\d])(\d)$/
		)
	)
		return false;
	return true;
};

export const isPhoneValid = (phone: string) => {
	if (phone === '') {
		return {
			valid: false,
			msg: 'Escribe tu teléfono.',
		};
	} else if (!isNumber(phone) || phone.length < 10) {
		return {
			valid: false,
			msg: 'Escribe los 10 dígitos de tu número telefónico incluyendo la clave Lada, sin guiones ni espacios. Intenta de nuevo.',
		};
	}
	return { valid: true, msg: '' };
};

export const isFolioValid = (value: string) => {
	const executorFolioFormat =
		/[0-9]{2}\/[0-9]{4}\/[0-9]{1}-[0-9]{4}\/[0-9]{5}[a-zA-Z]{3}/;
	const notifyingFolioFormat = /[0-9]{2}\/[0-9]{4}\/[0-9]{1}-[0-9]{4}\/[0-9]{5}/;
	//30/3520/2-2014/00028PAE
	if (value === '') {
		return {
			valid: false,
			msg: 'Ingresa un número de folio o constancia ',
		};
	} else if (
		(value.match(notifyingFolioFormat) && value.length === 20) ||
		(value.match(executorFolioFormat) && value.length === 23)
	) {
		return {
			valid: true,
			msg: '',
		};
	} else {
		return {
			valid: false,
			msg: 'El número de folio o constancia no es válido',
		};
	}
};

export const validateLetrasPwd = (letras: string) => {
	if (letras.length > 12) {
		return false;
	}
	if (letras.charCodeAt(0) === 32) {
		return false;
	}

	if (letras.charCodeAt(0) === 241) {
		return false;
	}

	if (letras.charCodeAt(0) !== 241 && letras.length > 1) {
		if (letras.charCodeAt(letras.length - 1) === 241) {
			return false;
		}
	}

	if (letras.charCodeAt(0) !== 32 && letras.length > 1) {
		if (letras.charCodeAt(letras.length - 1) === 32) {
			return false;
		}
	}
	return true;
};

export const validNumberRepeat = (letras: string) => {
	let valid = 0;
	let numberAnterior = -1;
	letras.split('').forEach((e) => {
		const numberActual = Number(e);
		if (!isNaN(Number(numberActual)) && valid < 3) {
			if (numberAnterior < 0) {
				numberAnterior = numberActual;
				return;
			}
			const diferencia = numberActual - numberAnterior;
			numberAnterior = numberActual;
			if (diferencia === 1) {
				valid++;
				return;
			}
			valid = 0;
			return;
		}
		if (valid < 3) {
			valid = 0;
			numberAnterior = -1;
		}
	});
	return valid >= 3;
};
