import React, { Fragment } from 'react';
import { useNavigate } from 'react-router-dom';
import { CustomAlert } from '../../../../components';
import CustomButton from '../../../../components/button.component';
import CustomModal from '../../../../components/modal.component';
import { IDatosEfirma } from '../../../../interfaces/efirma.interface';
import { useConfirmation } from './hooks/useConfirmation';
import { useMediaQuery, useTheme } from '@mui/material';

import styles from './styles/styles.component.module.css';
import DownloadButton from '../../../affiliation-movements/components/downloadButton.component';
import { URLS_ENV } from '../../../../constants/urls.constants';

export interface IConfirmation {
	setActualPage: React.Dispatch<React.SetStateAction<'form' | 'confirmation'>>;
	pdf: string;
	setPdf: React.Dispatch<React.SetStateAction<string>>;
	folio: string;
	setFolio: React.Dispatch<React.SetStateAction<string>>;
	cadenaOriginal: string;
	setCadenaOriginal: React.Dispatch<React.SetStateAction<string>>;
	nrp: string;
	setNrp: React.Dispatch<React.SetStateAction<string>>;
	eFirmaData: IDatosEfirma;
	setEFirmaData: React.Dispatch<React.SetStateAction<IDatosEfirma>>;
}

const Confirmation = (props: IConfirmation) => {
	const matches = useMediaQuery(useTheme().breakpoints.down('sm'));
	const navigate = useNavigate();
	const {
		fileSrc,
		isAccepted,
		showModal,
		modalMessage,
		modalType,
		handleGenerateReceipt,
		handleDownloadPDF,
		handleCancelRemoval,
		handleDownloadRecipt,
	} = useConfirmation(props);

	return (
		<Fragment>
			<CustomModal
				openModal={showModal}
				labelButton="Finalizar"
				closeButton={true}
				type={modalType}
				callback={() => navigate('/')}
			>
				<span className={styles.modalMessage}>{modalMessage}</span>
			</CustomModal>
			<div className={styles.alertsContainer}>
				<div className={styles.alert}>
					Recibo de solicitud de baja del representante
				</div>
				{isAccepted && (
					<CustomAlert
						show={true}
						severity={'success'}
						message={'Baja patronal exitosa.'}
						hasMargin={false}
					/>
				)}
				<span className={styles.txtInfo}>
					Si tienes dudas puedes descargar la{' '}
					<a href={URLS_ENV.LEGAL_REP_GUIDE}>Guía de usuario</a>{' '}
					correspondiente.
				</span>
			</div>
			<div className={styles.visorContainer}>
				{matches ? (
					<div
						style={{
							display: 'flex',
							flexDirection: 'column',
							gap: '20px',
							justifyContent: 'center',
							alignItems: 'center',
							marginBottom: '40px',
						}}
					>
						<span style={{ fontSize: '18px', fontWeight: '400' }}>
							Para asegurarte que la información sea correcta, descarga el
							recibo.
						</span>
						<DownloadButton
							onClick={handleDownloadRecipt}
							text="Descargar recibo de baja"
							customStyles={{ width: 'auto' }}
						/>
					</div>
				) : (
					<div>
						<object
							type="application/pdf"
							data={fileSrc}
							max-width="850px"
							width="100%"
							height="500px"
						>
							<iframe
								src={fileSrc}
								max-width="850px"
								width="100%"
								height="500px"
								title="pdf"
							/>
						</object>
					</div>
				)}
				<div className={styles.buttonsContainer}>
					{isAccepted ? (
						<CustomButton
							onClick={handleDownloadPDF}
							styles={{ width: '240px' }}
							variant="solid"
							label="Descargar PDF"
						/>
					) : (
						<Fragment>
							<CustomButton
								onClick={handleCancelRemoval}
								styles={{ width: '240px' }}
								variant="outlined"
								label="Cancelar"
							/>
							<CustomButton
								onClick={handleGenerateReceipt}
								styles={{ width: '240px' }}
								variant="solid"
								label="Generar recibo"
							/>
						</Fragment>
					)}
				</div>
			</div>
		</Fragment>
	);
};

export default Confirmation;
