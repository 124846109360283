/* eslint-disable react-hooks/exhaustive-deps */
// Dependencies
import { useState } from 'react';
import styles from './style.module.css';

// Components
import { Grid } from '@mui/material';
import Footer from '../../components/footer.component';

import Information from '../../components/information.component';
import CustomizedSteppers from '../../components/custom-stepper.component';
import PaperLogin from '../../components/paper-login.component';
import Step1 from './components/step-1';
import Step2 from './components/step-2';
import Step3 from './components/step-3';
import CreateAccountProvider from './context/CreateAccountProvider';

const CrearCuenta = () => {
	const steps = ['Información', 'Validación', 'Activación'];
	const [actualStep, setActualStep] = useState(0);
	const [showPwd1, setShowPwd1] = useState(true);
	const [nrpHasEmission, setNrpHasEmission] = useState<boolean>(true);
	const [nrpEmissionMessage, setNrpEmissionMessage] = useState<string>('');
	return (
		<CreateAccountProvider>
			<Grid className={styles.subContainer} container spacing={3}>
				<Grid
					className={styles.mainContainerForm}
					item
					xs={12}
					sm={12}
					md={12}
					lg={12}
				>
					<PaperLogin>
						<div
							style={{
								display: 'flex',
								flexDirection: 'column',
								width: '100%',
								justifyContent: 'center',
								alignItems: 'center',
							}}
						>
							<h4 style={{ marginBottom: '40px' }}>Crea una cuenta</h4>
							<CustomizedSteppers step={actualStep} steps={steps} />
							<div style={{ marginBottom: '40px' }} />
							{actualStep === 0 ? (
								<Step1
									setActualStep={setActualStep}
									setShowPwd1={setShowPwd1}
									setNrpHasEmission={setNrpHasEmission}
									setNrpEmissionMessage={setNrpEmissionMessage}
								/>
							) : (
								''
							)}
							{actualStep === 1 ? (
								<Step2
									setActualStep={setActualStep}
									showPwd1={showPwd1}
									setShowPwd1={setShowPwd1}
									nrpHasEmission={nrpHasEmission}
									setNrpHasEmission={setNrpHasEmission}
									nrpEmissionMessage={nrpEmissionMessage}
								/>
							) : (
								''
							)}
							{actualStep === 2 ? <Step3 /> : ''}
						</div>
					</PaperLogin>
				</Grid>
			</Grid>
			<Information />
			<Footer />
		</CreateAccountProvider>
	);
};

export default CrearCuenta;
