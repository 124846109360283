/* eslint-disable react-hooks/exhaustive-deps */

import React, { useContext, useEffect, useMemo, useState } from 'react';
import { IAuthNrpParams, IAuthResponse } from '../interfaces/auth.interface';
import { emailValid, nrpValid, compareEmails } from '../../../../../utils/validators';
import { useApiRequest } from '../../../../../hooks/useApiRequest';
import { INTERNAL_CODES } from '../../../../../config/response-codes';
import { AUTH_CODES, BIMESTER_STRINGS } from '../constants/auth.constants';
import { URLS } from '../../../../../constants/urls.constants';
import { EMPTY_FIELDS } from '../../../constants/empty-field-messages.constanst';
import { CreateAccountContext } from '../../../context/CreateAccountContext';
import { useDispatch } from 'react-redux';
import { setLoadingRequest } from '../../../../../store/modules/loadingRequest';

export function useStep1(props: any) {
	const dispatch = useDispatch();
	const { createAccountData, setCreateAccountData, setShowPwd } =
		useContext(CreateAccountContext);

	const [confirmacionCorreo, setConfirmacionCorreo] = useState<string>('');
	const [code, setCode] = useState('');
	const [codeInput, setCodeInput] = useState('');
	const [refreshCaptcha, setRefreshCaptcha] = useState(false);
	const [validCaptcha, setValidCaptcha] = useState(false);
	const [errorNRP, setErrorNRP] = useState<{
		valid: boolean;
		msg: string | JSX.Element;
	}>({ valid: false, msg: '' });
	const [errorCorreo, setErrorCorreo] = useState({ valid: false, msg: '' });
	const [errorConfirmacionCorreo, setErrorConfirmacionCorreo] = useState({
		valid: false,
		msg: '',
	});
	const [errorCaptcha, setErrorCaptcha] = useState({ valid: false, msg: '' });

	const [showModal, setShowModal] = useState(false);

	const {
		execute,
		value: valueAuth,
		status,
	} = useApiRequest<IAuthNrpParams, IAuthResponse>({
		path: URLS.CREATE_ACCOUNT.VALIDATION,
	});

	const disabledButton = useMemo((): boolean => {
		if (
			emailValid(createAccountData.correo).valid &&
			nrpValid(createAccountData.nrp).valid &&
			compareEmails(createAccountData.correo, confirmacionCorreo).valid &&
			codeInput.length > 0
		) {
			return false;
		} else return true;
	}, [createAccountData.nrp, createAccountData.correo, confirmacionCorreo, codeInput]);

	const handleSetData = (event: React.ChangeEvent<HTMLInputElement>): void => {
		const { name, value } = event.target;
		if (name === 'nrp') {
			if (!value.match(/^[a-zA-Z0-9]*$/)) return;
			let validate = { valid: false, msg: '' };
			if (value.length > 11) return;
			validate = nrpValid(value);
			setErrorNRP(validate);
			setCreateAccountData({ ...createAccountData, [name]: value.toUpperCase() });
		} else {
			if (value.match(/[ñÑáéíóúÁÉÍÓÚ]/) || !value.match(/^[A-Za-z0-9_.@-]*$/))
				return;
			if (name === 'correo') {
				const validate = emailValid(value);
				setErrorCorreo({ valid: validate.valid, msg: validate.msg });
				setCreateAccountData({ ...createAccountData, [name]: value });
			} else if (name === 'confirmacionCorreo') {
				if (value === '') {
					setErrorConfirmacionCorreo({
						valid: false,
						msg: 'Escribe tu correo electrónico de confirmación.',
					});
				} else {
					const valitateEquals = compareEmails(createAccountData.correo, value);
					setErrorConfirmacionCorreo({
						valid: valitateEquals.valid,
						msg: valitateEquals.msg,
					});
				}
				setConfirmacionCorreo(value);
			}
		}
	};

	const handleValidate = () => {
		if (createAccountData.nrp.length === 0) {
			return setErrorNRP({ valid: false, msg: EMPTY_FIELDS.NRP });
		} else if (createAccountData.correo.length === 0) {
			return setErrorCorreo({ valid: false, msg: EMPTY_FIELDS.EMAIL });
		} else if (!code || codeInput !== code) {
			setRefreshCaptcha(!refreshCaptcha);
			return setValidCaptcha(true);
		}
		handleOnSubmit();
	};

	const handleOnSubmit = () => {
		props.setNrpHasEmission(true);
		dispatch(setLoadingRequest({ loadingRequest: true }));

		setCreateAccountData(createAccountData);

		execute({
			nrp: createAccountData.nrp,
			correo: createAccountData.correo,
			confirmacionCorreo: confirmacionCorreo,
		});
	};

	useEffect(() => {
		handleValidateAuth();
		setErrorCaptcha({
			valid: true,
			msg: '',
		});
	}, [valueAuth, status]);

	const getEmissionSring = (period: string): void => {
		const year = period.substring(0, 2);
		const bimester = period.substring(2, 4);
		const finalString = `${BIMESTER_STRINGS[bimester]} 20${year}`;
		props.setNrpEmissionMessage(finalString);
	};

	const validateNrpHasEmission = (response: string): boolean => {
		if (response.split('|')[1].length > 1) {
			getEmissionSring(response.split('|')[1]);
		}
		return response.split('|')[1].length < 1;
	};

	const handleValidateAuth = () => {
		if (status === 'success') {
			dispatch(setLoadingRequest({ loadingRequest: false }));
			switch (valueAuth?.code) {
				case '0007':
					props.setShowPwd1(false);
					setShowPwd(false);

					if (validateNrpHasEmission(valueAuth.message)) {
						props.setNrpHasEmission(false);
					}
					props.setActualStep(1);
					window.scrollTo(0, 0);
					break;
				case '0005':
					if (validateNrpHasEmission(valueAuth.message)) {
						props.setNrpHasEmission(false);
					}
					props.setShowPwd1(true);
					setShowPwd(true);
					props.setActualStep(1);
					window.scrollTo(0, 0);
					break;

				case '0001':
					setErrorNRP({
						valid: false,
						msg: AUTH_CODES['0001'],
					});
					setRefreshCaptcha(!refreshCaptcha);
					break;
				case '0002':
					setErrorNRP({
						valid: false,
						msg: (
							<span>
								{AUTH_CODES['0002'] + ' '}
								<a
									href="/olvide-contrasenia"
									style={{ color: '#293990' }}
								>
									aquí
								</a>
								.
							</span>
						),
					});
					setRefreshCaptcha(!refreshCaptcha);
					break;
				case '0003':
					setErrorNRP({
						valid: false,
						msg: (
							<span>
								{AUTH_CODES['0003'] + ' '}
								<a href="/activa-registro" style={{ color: '#293990' }}>
									aquí
								</a>
								.
							</span>
						),
					});
					setRefreshCaptcha(!refreshCaptcha);
					break;
				case '0004':
					setErrorNRP({
						valid: false,
						msg: AUTH_CODES['0004'],
					});
					setRefreshCaptcha(!refreshCaptcha);
					break;
				default:
					setShowModal(true);
					break;
			}
		} else if (
			status === 'error' &&
			valueAuth?.internalCode !== INTERNAL_CODES.SUCCESSFUL
		) {
			dispatch(setLoadingRequest({ loadingRequest: false }));
			setShowModal(true);
			setRefreshCaptcha(!refreshCaptcha);
		}
	};

	return {
		setCode,
		setCodeInput,
		handleSetData,
		handleValidate,
		setRefreshCaptcha,
		setValidCaptcha,
		setShowModal,
		setErrorCaptcha,
		alert,
		confirmacionCorreo,
		errorNRP,
		errorCorreo,
		errorConfirmacionCorreo,
		codeInput,
		refreshCaptcha,
		validCaptcha,
		showModal,
		errorCaptcha,
		disabledButton,
	};
}
