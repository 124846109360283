import { Fragment } from 'react';
/* eslint-disable react-hooks/exhaustive-deps */
// Resources
import styles from './style.module.css';

// Components
import { Grid } from '@mui/material';
import Footer from '../../components/footer.component';
import Information from '../../components/information.component';
import PaperLogin from '../../components/paper-login.component';
import { TextInput } from '../../components';
import Captcha from '../../components/captcha.component';
import CustomButton from '../../components/button.component';
import { useActiveRegister } from './hooks/useActiveRegister';
import CustomModal from '../../components/modal.component';
import { INTERNAL_CODES, RESPONSE_CODES } from '../../config/response-codes';
import SuccessfullyRegister from './components/succesfully-register';
import Loading from '../../assets/gifts/loading.gif';

export default function ActivateRegisterPage() {
	const {
		data,
		alert,
		codeInput,
		refreshCaptcha,
		errorCorreo,
		errorConfirmaCorreo,
		errorNrp,
		errorCaptcha,
		validForm,
		valueRegister,
		status,
		openModal,
		isRequestLoading,
		handleSetData,
		setCode,
		setCodeInput,
		setErrorCaptcha,
		handleValidateFormFields,
		handleValidate,
		setOpenModal,
	} = useActiveRegister();
	return (
		<Fragment>
			<Grid className={styles.subContainer} container spacing={0}>
				<PaperLogin>
					<Fragment>
						<h4>Activa tu registro</h4>
						<div className={styles.label}>
							<p className={styles.txtIntro}>
								Tu cuenta está inactiva, escribe los siguientes datos para
								que te reenviemos el correo en donde podrás hacerlo.
							</p>
						</div>
						<TextInput
							id="nrp"
							name="nrp"
							labelStyles={{ fontSize: 16 }}
							value={data.nrp}
							onChange={handleSetData}
							labelComponent={
								<p className={styles.labelContainerInput}>
									Número de Registro Patronal (NRP)
									<span className="mandatory">*</span>
								</p>
							}
							error={!errorNrp.valid}
							helperText={!errorNrp.valid ? errorNrp.msg : ''}
						/>
						<TextInput
							id="correo"
							name="correo"
							labelStyles={{ fontSize: 16 }}
							value={data.correo}
							onChange={handleSetData}
							labelComponent={
								<p className={styles.labelContainerInput}>
									Correo electrónico
									<span className="mandatory">*</span>
								</p>
							}
							noPaste
							noCopy
							error={!errorCorreo.valid}
							helperText={!errorCorreo.valid ? errorCorreo.msg : ''}
						/>

						<TextInput
							id="confirmaCorreo"
							name="confirmaCorreo"
							noPaste
							noCopy
							labelStyles={{ fontSize: 16 }}
							value={data.confirmaCorreo}
							onChange={handleSetData}
							labelComponent={
								<p className={styles.labelContainerInput}>
									Confirma correo electrónico
									<span className="mandatory">*</span>
								</p>
							}
							error={!errorConfirmaCorreo.valid}
							helperText={
								!errorConfirmaCorreo.valid ? errorConfirmaCorreo.msg : ''
							}
						/>
						<div className={styles.label}>
							<p className="Text-Small" style={{ margin: '0px' }}>
								<span className="mandatory">*</span>Datos obligatorios
							</p>
						</div>
						<div className={styles.containerCaptcha}>
							<Captcha
								value={codeInput}
								getNew={refreshCaptcha}
								onChange={(newValue: string, codeCaptcha: string) => {
									handleValidateFormFields(
										data.nrp,
										data.correo,
										data.confirmaCorreo,
										newValue.length > 0 ? true : false
									);
									setCodeInput(newValue);
									setCode(codeCaptcha);
								}}
								error={errorCaptcha}
								setError={setErrorCaptcha}
							/>
						</div>
						<div className={styles.containerButtonsForm}>
							{isRequestLoading ? (
								<div
									style={{
										width: '240px',
										height: '40px',
										display: 'flex',
										justifyContent: 'center',
									}}
								>
									<img src={Loading} alt="LoadingIcon" height={48} />
								</div>
							) : (
								<CustomButton
									onClick={handleValidate}
									variant="solid"
									label="Enviar"
									disabled={!validForm}
								/>
							)}
						</div>
					</Fragment>
				</PaperLogin>
				{(status === 'success' &&
					valueRegister?.code !== RESPONSE_CODES.SUCCESSFUL) ||
				(status === 'error' &&
					valueRegister?.internalCode !== INTERNAL_CODES.SUCCESSFUL) ? (
					<CustomModal
						openModal={openModal}
						setOpenModal={setOpenModal}
						title={alert.title}
						type={alert.type}
						labelButton="Cerrar"
						closeButton={true}
						style={{ fontSize: 18, fontWeight: 400 }}
					>
						<Fragment>{alert.body}</Fragment>
					</CustomModal>
				) : status === 'success' &&
				  valueRegister?.code === RESPONSE_CODES.SUCCESSFUL ? (
					<SuccessfullyRegister correo={data.correo} />
				) : null}
			</Grid>
			<Information />
			<Footer />
		</Fragment>
	);
}
