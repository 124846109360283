import { useState } from 'react';
import {
	defaultCreateAccountData,
	ICreateUserParams,
} from '../interfaces/create-user.interface';
import { CreateAccountContext } from './CreateAccountContext';

interface ICreateAccountProvider {
	children: JSX.Element | JSX.Element[];
}

const CreateAccountProvider = ({ children }: ICreateAccountProvider) => {
	const [createAccountData, setCreateAccountData] = useState<ICreateUserParams>(
		defaultCreateAccountData
	);
	const [showPwd, setShowPwd] = useState<boolean>(true);

	return (
		<CreateAccountContext.Provider
			value={{
				createAccountData,
				setCreateAccountData,
				showPwd,
				setShowPwd,
			}}
		>
			{children}
		</CreateAccountContext.Provider>
	);
};

export default CreateAccountProvider;
