import { SetStateAction } from 'react';

export interface ICreateUserParams {
	apMaterno?: string;
	apPaterno: string;
	correo: string;
	curp: string;
	eba?: string;
	montoUltimoPago?: string;
	nombre: string;
	nrp: string;
	origen?: string;
	psw?: string;
	periodoUltimoPago?: string;
	rfc: string;
	rfcEfirma?: string;
	telefono: string;
}

export interface ICreateUserResponse {
	code: string;
	internalCode: string;
}

export const defaultCreateAccountData: ICreateUserParams = {
	apMaterno: '',
	apPaterno: '',
	correo: '',
	curp: '',
	nombre: '',
	nrp: '',
	psw: '',
	rfc: '',
	telefono: '',
	eba: '',
};

export interface ICreateAccountContext {
	createAccountData: ICreateUserParams;
	setCreateAccountData: React.Dispatch<SetStateAction<ICreateUserParams>>;
	showPwd: boolean;
	setShowPwd: React.Dispatch<SetStateAction<boolean>>;
}
