import { useState, useContext } from 'react';
import { IGeneralDataForm } from '..';
import {
	isCurp,
	isEmpty,
	isNumber,
	isPwdValid,
	nameHasSpecialCharacters,
} from '../../../../../../../utils/validators';
import { EMPTY_FIELDS } from '../../../../../constants/empty-field-messages.constanst';
import { CreateAccountContext } from '../../../../../context/CreateAccountContext';
import { accentRegex } from '../../../constants/constants';

export function useGeneralDataForm({
	setPwdConfirmation,
	setErrorContrasenia,
	setErrorConfirmacionContrasenia,
}: IGeneralDataForm) {
	const { createAccountData, showPwd, setCreateAccountData } =
		useContext(CreateAccountContext);

	const [errorNombre, setErrorNombre] = useState({ valid: false, msg: '' });
	const [errorPrimerApellido, setErrorPrimerApellido] = useState({
		valid: false,
		msg: '',
	});
	const errorSegundoApellido = { valid: false, msg: '' };
	const [errorRFC, setErrorRFC] = useState({ valid: false, msg: '' });
	const [errorCURP, setErrorCURP] = useState({ valid: false, msg: '' });
	const [errorTelefono, setErrorTelefono] = useState({ valid: false, msg: '' });

	const handleSetData = (event: React.ChangeEvent<HTMLInputElement>): void => {
		const { name, value } = event.target;

		if (name === 'nombre') {
			const invalid = nameHasSpecialCharacters(value);
			if (invalid) return;
			if (isEmpty(value)) {
				setErrorNombre({ valid: false, msg: EMPTY_FIELDS.NOMBRE });
			} else {
				setErrorNombre({ valid: true, msg: '' });
			}
		} else if (name === 'apPaterno') {
			const invalid = nameHasSpecialCharacters(value);
			if (invalid) return;
			if (isEmpty(value)) {
				setErrorPrimerApellido({
					valid: false,
					msg: EMPTY_FIELDS.APELLIDO_PATERNO,
				});
			} else {
				setErrorPrimerApellido({ valid: true, msg: '' });
			}
		} else if (name === 'apMaterno') {
			const invalid = nameHasSpecialCharacters(value);
			if (invalid) return;
		} else if (name === 'rfc') {
			if (!value.match(/^[a-zA-Z0-9]*$/)) return;
			const validRFC = value.match(/^[A-Z]{4}[0-9]{6}[A-Z0-9]{3}$/);
			if (isEmpty(value)) {
				setErrorRFC({ valid: false, msg: EMPTY_FIELDS.RFC });
			} else if (!validRFC) {
				setErrorRFC({ valid: false, msg: EMPTY_FIELDS.RFC_NOT_VALID });
			} else if (validRFC) {
				setErrorRFC({ valid: true, msg: '' });
			}
		} else if (name === 'curp') {
			if (!value.match(/^[a-zA-Z0-9]*$/)) return;
			const validCURP = isCurp(value);

			if (isEmpty(value)) {
				setErrorCURP({ valid: false, msg: EMPTY_FIELDS.CURP });
			} else if (!validCURP) {
				setErrorCURP({ valid: false, msg: EMPTY_FIELDS.CURP_NOT_VALID });
			} else if (validCURP) {
				setErrorCURP({ valid: true, msg: '' });
			}
		} else if (name === 'telefono') {
			if (!isNumber(value)) return;
			if (isEmpty(value)) {
				setErrorTelefono({
					valid: false,
					msg: EMPTY_FIELDS.TELEFONO,
				});
			} else if (value.length < 10) {
				setErrorTelefono({
					valid: false,
					msg: EMPTY_FIELDS.TELEFONO_NOT_VALID,
				});
			} else {
				setErrorTelefono({ valid: true, msg: '' });
			}
		}
		if (showPwd) {
			if (name === 'psw') {
				if (value.match(accentRegex)) return;
				if (
					value.includes('<') ||
					value.includes('>') ||
					value.includes('\\') ||
					value.includes('&') ||
					value.includes('|')
				)
					return;
				const validate = isPwdValid(value);
				if (value.length > 0) {
					setErrorContrasenia({ valid: validate.valid, msg: '' });
				} else {
					setErrorContrasenia(validate);
				}
				setCreateAccountData({ ...createAccountData, [name]: value });
				return;
			} else if (name === 'pwdConfirmation') {
				if (value.match(accentRegex)) return;
				const validate = isEmpty(value);
				if (validate) {
					setErrorConfirmacionContrasenia({
						valid: false,
						msg: EMPTY_FIELDS.CONFIRMACION_CONTRASENIA,
					});
				} else if (value !== createAccountData.psw) {
					setErrorConfirmacionContrasenia({
						valid: false,
						msg: EMPTY_FIELDS.CONFIRMACION_CONTRASENIA,
					});
				} else {
					setErrorConfirmacionContrasenia({
						valid: true,
						msg: '',
					});
				}
				setPwdConfirmation(value);
				return;
			}
		}
		setCreateAccountData({ ...createAccountData, [name]: value.toUpperCase() });
	};

	return {
		errorNombre,
		errorPrimerApellido,
		errorSegundoApellido,
		errorRFC,
		errorCURP,
		errorTelefono,
		// errorContrasenia,
		// errorConfirmacionContrasenia,
		handleSetData,
	};
}
